<template>
  <div class="think_tank_container">
    <!-- 顶部导航的占位 -->
    <QbTopnavPlaceholder></QbTopnavPlaceholder>

    <!-- 智囊团陪跑计划标题  -->
    <QbTitle
      :title="title"
      :complement="complement"
      :complementChangeStyle="true"
    >
    </QbTitle>

    <!-- 智囊团陪跑计划内容  -->
    <thinkTankContent></thinkTankContent>

    <!-- 智囊团咨询团队 -->
    <ThinkTankTeam></ThinkTankTeam>

    <!-- 智囊团服务 -->
    <ThinkService></ThinkService>

    <!-- 智囊团客户案例 -->
    <ThinkCase></ThinkCase>

    <!-- 报名表 -->
    <QbApplication></QbApplication>
  </div>
</template>

<script>
import thinkTankContent from './components/think-tank-content.vue'
import ThinkTankTeam from './components/think-advisory-team.vue'
import ThinkService from './components/think_service.vue'
import ThinkCase from './components/think_client_case.vue'
export default {
  name: 'ThinkTank',
  components: {
    thinkTankContent,
    ThinkTankTeam,
    ThinkService,
    ThinkCase
  },
  data() {
    return {
      title: '《智囊团<i class="iconfont icon-Rbiao R"></i>陪跑计划》',
      complement: '选对正确赛道 全程加速陪跑智囊倾力相助 迅速落地见效'
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .R {
  display: inline-block;
  transform: translateY(-28px) scale(1.2);
}
</style>
