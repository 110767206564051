<template>
  <div class="think_client_case_container w">
    <div class="client_case_header">
      <h3 class="title">智囊团客户案例</h3>
      <div class="divider"></div>
      <h3 class="en_title">THINK TANK CLIENT CASE</h3>
    </div>
    <div class="client_case_main">
      <div class="client_case_aside">
        <div
          class="case_menu_item"
          v-for="item in clientList"
          :key="item.id"
          @click="handleSelect(item)"
        >
          <img v-if="item.id !== currentId" :src="item.pictrue" alt="" />
          <img v-else :src="item.currentPictrue" alt="" />
        </div>
      </div>
      <div class="client_case_content">
        <div v-for="item in clientCaseList" :key="item.id">
          <template v-if="item.id === currentId">
            <img class="cover_image" :src="item.pictrue" alt="" />
            <div class="footer">
              <h3 class="title">{{ item.title }}</h3>
              <div class="under_line"></div>
              <div class="content">{{ item.content }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThinkClientCase',
  data() {
    return {
      clientList: [
        {
          id: 1,
          pictrue: require('@/assets/images/think/client_case_1.png'),
          currentPictrue: require('@/assets/images/think/client_case_select_1.png')
        },
        {
          id: 2,
          pictrue: require('@/assets/images/think/client_case_2.png'),
          currentPictrue: require('@/assets/images/think/client_case_select_2.png')
        },
        {
          id: 3,
          pictrue: require('@/assets/images/think/client_case_3.png'),
          currentPictrue: require('@/assets/images/think/client_case_select_3.png')
        },
        {
          id: 4,
          pictrue: require('@/assets/images/think/client_case_4.png'),
          currentPictrue: require('@/assets/images/think/client_case_select_4.png')
        },
        {
          id: 5,
          pictrue: require('@/assets/images/think/client_case_5.png'),
          currentPictrue: require('@/assets/images/think/client_case_select_5.png')
        },

        {
          id: 6,
          pictrue: require('@/assets/images/think/client_case_6.png'),
          currentPictrue: require('@/assets/images/think/client_case_select_6.png')
        },
        {
          id: 7,
          pictrue: require('@/assets/images/think/client_case_7.png'),
          currentPictrue: require('@/assets/images/think/client_case_select_7.png')
        },
        {
          id: 8,
          pictrue: require('@/assets/images/think/client_case_8.png'),
          currentPictrue: require('@/assets/images/think/client_case_select_8.png')
        }
      ],
      clientCaseList: [
        {
          id: 1,
          pictrue: require('@/assets/images/think/client_case_cover_1.png'),
          title: '易先生  密室机关',
          content:
            '我创业13年一路走来起起伏伏，有的产业成功了，有的以失败收场，尤其是在2014年，一年的时间关掉三家公司， 终于让我意识到学习的重要性。于是我开始四处游学，求取真经，寻求变革之法。在我来到泉邦之前，那一年我花了130万的学费去到北上广深一共18次，也报了当下很多所谓名师的课程，但是慢慢发现从刚开始学习的兴奋、激动，期待到后来越学越迷茫…'
        },
        {
          id: 2,
          pictrue: require('@/assets/images/think/client_case_cover_2.png'),
          title: '李杰荣  美容行业',
          content:
            '我和我的合作伙伴梁星煜老师学习完《裂变式盈利》后，梳理出门店加盟模式、门店入股方案以及消费型股东方案,短短两个月时间,签约150家合作门店约1000万,区域城市分公司签约10家300万, 10万的资源型股东60个收款600万,并制定了2019年底2000家目标的执行规划,目前累计招募代理商5000余家，收款总额超过1.5个亿。通过系统的咨询服务让我们跳出了传统经营的观念,从卖货思维升级到用户思维,从差价思维跳转到分享思维,感恩泉邦。'
        },
        {
          id: 3,
          pictrue: require('@/assets/images/think/client_case_cover_3.png'),
          title: '姚冲 厦门黛蒙斯贸易',
          content:
            '自主创立品牌4年，在微商行业扎根5年，经历过几年来行业的动荡起伏。之前学习过各种营销课程，但是效果都不落地，直到2018年12月通过了《裂变式盈利》课堂认识到泉邦集团在设计企业架构及渠道管理的专业性，果断加入智囊团。通过咨询服务为新项目做了全新的顶层设计，并完善了各渠道的建设。利用资源型股东和投资型股东的方案重新招募了10多位合作股东，捆绑了各行业的资源。在还没有…'
        },
        {
          id: 4,
          pictrue: require('@/assets/images/think/client_case_cover_4.png'),
          title: '王倩  美容行业',
          content:
            '进入泉邦第一个学习的是股东招募，以很快的速度招到第一、二批股东60人，真正打开了一条财富之路，150平的店投资最多只要80万，但是一天可以收回成本156万，而且每天群里都有战报雨。从背后可以看到我们团队的凝聚力、士气，我的格局也被拉大了。第二步就是我们的精英入股计划，我现在不但账上有钱，手上有客户，我还有团队，南京所有美业里的顶级金牌导师，只要到我家都会被我降服，为什么会被…'
        },
        {
          id: 5,
          pictrue: require('@/assets/images/think/client_case_cover_5.png'),
          title: '李茂宏 环球礼仪商学院',
          content:
            '我是做礼仪培训的，做礼仪培训十年，服务众多企业，在礼仪培训领域一直扎根，也小有成绩。但是一直重复做最初的模式运营，学员通过7天的学习毕业后就没有太多链接，资源都浪费了。通过“智囊团”的咨询服务，马老师帮我建立了辐射全国的“版图代理”模式，通过这个模式我每期课程的业绩翻了2-3倍，而且还把合作机构开到了全国各地。'
        },
        {
          id: 6,
          pictrue: require('@/assets/images/think/client_case_cover_6.png'),
          title: '姚飞 南通凯富贸易',
          content:
            '我是做生鲜猪肉连锁的，之前只是靠卖生鲜猪肉和供应链管理来获取利润点。但通过“智囊团”的咨询服务，转变了传统思维理念，把成本变收入，把投资变融资，同时马老师帮我建立“直管模式”并梳理出10个盈利点，每一项都很有吸引力让我对这份事业有了更大的信心，并且可以快速实现门店裂变。'
        },
        {
          id: 7,
          pictrue: require('@/assets/images/think/client_case_cover_7.png'),
          title: '肖总  瑜伽行业',
          content:
            '短短半个月时间，新店投资股东9人收款90万，新店资源股东6人收款12万，目前收款总额102万。'
        },
        {
          id: 8,
          pictrue: require('@/assets/images/think/client_case_cover_8.png'),
          title: '徐凌静 安徽省盖亚映画摄影',
          content:
            '我从事婚纱影楼已经6年了，因为婚纱摄影行业的特殊性,  我们获客手段单一，成本高，复购率低,  事业难以得到发展，在这样的困境下，通过朋友介绍，我来到了泉邦，学习了《裂变式盈利》的课程后，毅然加入了智囊团，资源型和投资型股东方案帮助我梳理了产品结构，开拓了新的产品线，捆绑了多年来的优质客户，企业成功实现从传统至新型的转型，突破了企业发展中的瓶颈，找到了更多像我一样为公司的发展，努力拼搏的人。'
        }
      ],
      currentId: 1
    }
  },
  methods: {
    handleSelect(item) {
      this.currentId = item.id
    }
  }
}
</script>

<style lang="less" scoped>
.think_client_case_container {
  padding-left: 181px;
}

.client_case_header {
  margin-bottom: 53px;
  .title {
    font-size: 37px;
    font-weight: 500;
    line-height: 53px;
  }
  .divider {
    border-bottom: 1px solid #979797;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .en_title {
    font-size: 21px;
    font-weight: 100;
    line-height: 29px;
    letter-spacing: 9px;
  }
}

.client_case_main {
  display: flex;
  .client_case_aside {
    height: 772px;
    width: 491px;
    overflow-x: hidden;
    overflow-y: scroll;
    .case_menu_item {
      height: 201px;
      width: 491px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .client_case_content {
    width: 1067px;
    height: 804px;
    .cover_image {
      width: 1067px;
      height: 504px;
      display: block;
    }
    .footer {
      padding: 27px 21px 38px 29px;
      height: 268px;
      width: 100%;
      background: #f7f9fd;
    }
    .title {
      font-size: 28px;
      font-weight: 600;
      color: #010343;
      line-height: 39px;
    }
    .under_line {
      width: 955px;
      border-bottom: 2px solid #979797;
      margin-top: 13px;
      margin-bottom: 17px;
    }
    .content {
      font-size: 24px;
      font-weight: 400;
      color: #010343;
      line-height: 33px;
      margin-left: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
  margin-bottom: 145px;
}
</style>
