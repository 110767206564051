<template>
  <div class="think_tank_content_container w">
    <div class="content">
      <img src="@/assets/images/think/thinktank_content.png" alt="" />
    </div>
    <div class="premise">
      <span>*</span>
      <span class="title">前提：</span>
      智囊团陪跑计划需经过筛选审核:1.确保您的公司及项目正向运作;
      <div class="item">2.方案给出后有执行力去启动;</div>
      <div class="item">3.有资源(未做过资金盘，直销等损伤人脉)</div>
    </div>
  </div>
</template>

<script>
export default {
  name: ''
}
</script>

<style lang="less" scoped>
.think_tank_content_container {
  padding-top: 108px;
  .content {
    width: 1409px;
    height: 904px;
    margin: 0 auto;
    margin-bottom: 113px;
  }
  .premise {
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 300;
    color: #313131;
    line-height: 44px;
    margin-top: 113px;
    margin-left: 181px;
    margin-bottom: 113px;
    span {
      color: #ff0000;
    }
    .title {
      color: #313131;
      font-weight: 500;
    }
    .item {
      text-indent: 18em;
    }
  }
}
</style>
