<template>
  <div class="advisory_team_container w">
    <div class="title">咨询团队</div>
    <div class="underline"></div>
    <div class="description">
      经过泉邦帮助服务的企业几乎涵盖全部业态，包括美业、服务、健康行业、零售行业、服装行业、母婴行业、教育行业等等，在各行各业都能取得超出预期的成效。
    </div>
    <!-- 咨询团队介绍 -->
    <div class="recommendPage">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="(item, index) in 25" :key="index">
          <img
            :src="require(`@/assets/images/think/think_team_${index + 1}.png`)"
            alt=""
          />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'

import 'swiper/dist/css/swiper.css'
export default {
  name: 'AdvisoryTeam',
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        slidesPerView: 5,
        spaceBetween: 30,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.advisory_team_container {
  width: 100%;
  margin-bottom: 131px;

  .title {
    font-size: 37px;
    font-weight: 500;
    line-height: 53px;
    margin-left: 183px;
  }
  .underline {
    border: 1px solid #979797;
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: 183px;
  }
  .description {
    font-size: 21px;
    font-weight: 100;
    line-height: 29px;
    margin-bottom: 56px;
    margin-left: 183px;
  }
  .recommendPage {
    width: 1920px;
    position: relative;
    height: 624px;
    overflow: hidden;
    .swiper-slide {
      width: 356px;
      height: 624px;
    }
    .swiper-button-prev {
      width: 105px;
      height: 105px;
      background: url(~@/assets/images/think/think_left.png) no-repeat;
      background-size: 100%;
      border-radius: 50%;
    }
    .swiper-button-next {
      width: 105px;
      height: 105px;
      background: rgba(151, 151, 151, 0.38);
      background: url(~@/assets/images/think/think_right.png) no-repeat;
      background-size: 100%;
      border-radius: 50%;
    }
  }
}
</style>
