<template>
  <div class="think_service_container w">
    <div class="service_process">
      <h3 class="process_title">智囊团陪跑计划服务流程</h3>
      <div class="process_content">
        <ul class="process_index">
          <li>01</li>
          <li>02</li>
          <li>03</li>
          <li>04</li>
          <li>05</li>
        </ul>
        <div class="split_line">
          <img src="@/assets/images/think/split_line.png" alt="" />
        </div>
        <ul class="process_stage">
          <li class="process_stage_item_1 process_stage_item">
            <div class="stage_title">咨询装备阶段</div>
            <ul class="stage_content">
              <li>
                1、调研:企业填写《智囊团信息采集报告》, 提供企业营销相关资料。
              </li>
              <li>
                2、定向:确定本次《智囊团回服务协议》咨询服务范围。
              </li>
              <li>
                3、立项:成立咨询项目小组统筹安排咨询推动工作。
              </li>
            </ul>
          </li>
          <li class="process_stage_item_2 process_stage_item">
            <div class="stage_title">敏捷咨询阶段</div>
            <ul class="stage_content">
              <li>
                1、 根据《信息采集报告》,咨询团队内部研讨并设立出基础方案框架。
              </li>
              <li>
                2、通过基于9+9模型的敏捷咨询,双方确定方案逻辑、内容与执行细节,达成阶段提案。
              </li>
            </ul>
          </li>
          <li class="process_stage_item_3 process_stage_item">
            <div class="stage_title">文件输出阶段</div>
            <ul class="stage_content">
              <li>
                1、双方沟通确定阶段方案所需配套文件(合同协议、机制)。
              </li>
              <li>
                2、配套协议的起草、律师审核、校对与最终输出。
              </li>
            </ul>
          </li>
          <li class="process_stage_item_4 process_stage_item">
            <div class="stage_title">方案落地阶段</div>
            <ul class="stage_content">
              <li>
                1、 审核:方案以及文件再次进行审核,确定细节以及推行流程。
              </li>
              <li>
                2、 帮扶:协助完善方案落地进程中所涉及的推动逻辑、方法和工具。
              </li>
              <li>
                3、 复盘:每阶段对方案推动过程中客户的反馈做系统收集,系统反馈。
              </li>
              <li>
                4、 优化:根据市场需求对方案进行一 定比例的调整优化。
              </li>
            </ul>
          </li>
          <li class="process_stage_item_5 process_stage_item">
            <div class="stage_title">跟进升级阶段</div>
            <ul class="stage_content">
              <li>
                1、跟踪:1年半咨询项目组免费跟进。
              </li>
              <li>
                2、升级:1年半方案免费优化升级。
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="service_module">
      <h3 class="title">智囊团3.0 服务模块</h3>
      <div class="service_content">
        <div>
          <dl class="service_item">
            <dt>产品代理咨询</dt>
            <dd>1.产品组合梳理</dd>
            <dd>2.代理机制设定</dd>
            <dd>3.推广策略支持</dd>
            <dd>4.财务利润推算</dd>
          </dl>
          <dl class="service_item">
            <dt>终端门店裂变咨询</dt>
            <dd>1.门店产品组合梳理</dd>
            <dd>2.门店渠道裂变策略</dd>
            <dd>3.门店加盟方案</dd>
            <dd>4.财务利润推算</dd>
          </dl>
        </div>
        <div class="square square_a">
          <span>A</span>
        </div>
        <div class="square square_b">
          <span>B</span>
        </div>
        <div class="square square_c">
          <span>C</span>
        </div>
        <div class="square square_d">
          <span>D</span>
        </div>
        <div>
          <dl class="service_item">
            <dt>顶层架构咨询</dt>
            <dd>1.组织架构梳理</dd>
            <dd>2.招人育人留人</dd>
            <dd>3.精英员工入股</dd>
            <dd>4.核心股东入股</dd>
          </dl>

          <dl class="service_item">
            <dt>融资方案咨询</dt>
            <dd>1.内部融资策略</dd>
            <dd>2.外部资金型股东融资方案</dd>
            <dd>3.外部资源型股东融资方案</dd>
            <dd>4.外部消费型股东融资方案</dd>
          </dl>
        </div>
      </div>
      <div class="value_add">
        <h3 class="value_add_title">增值服务</h3>
        <ul>
          <li>财务模型搭建</li>
          <li>员工入股计划</li>
          <li>销售流程梳理</li>
          <li>拓留锁升系统</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThinkService'
}
</script>

<style lang="less" scoped>
.think_service_container {
  margin-bottom: 96px;
  display: flex;
  justify-content: space-between;
  padding: 0 181px;
}

.service_process {
  display: flex;
  flex-direction: column;
  height: 939px;
  .process_title {
    font-size: 32px;
    font-weight: 500;
    color: #010343;
    margin-bottom: 56px;
  }
}

.process_content {
  display: flex;
  .process_index {
    display: flex;
    flex-direction: column;
    height: 783px;
    margin-right: 15px;
    margin-top: -10px;
    li {
      font-size: 80px;
      font-weight: bold;
      color: #010343;
      line-height: 96px;
      margin-bottom: 78px;
    }
  }
  .split_line {
    width: 5px;
    height: 783px;
  }
}

.process_stage {
  height: 783px;
  margin-right: 15px;
  margin-left: 31px;
  .process_stage_item {
    background: #f7f9fd;
    .stage_title {
      font-size: 19px;
      font-weight: 600;
      color: #010343;
      line-height: 36px;
    }
    .stage_content {
      font-size: 19px;
      font-weight: 500;
      color: #010343;
      line-height: 36px;
    }
  }

  .process_stage_item_1 {
    width: 609px;
    height: 156px;
    padding-left: 7px;
    margin-bottom: 15px;
  }

  .process_stage_item_2 {
    width: 609px;
    height: 156px;
    padding-top: 4px;
    padding-left: 7px;
    margin-bottom: 19px;
  }

  .process_stage_item_3 {
    width: 609px;
    height: 143px;
    // padding-top: 5px;
    padding-left: 7px;
    margin-bottom: 19px;
  }

  .process_stage_item_4 {
    width: 609px;
    height: 183px;
    padding-top: 9px;
    padding-left: 7px;
    margin-bottom: 19px;
  }
}

.service_module {
  width: 757px;
  height: 939px;
  background: #ffffff;
  padding-left: 27px;
  .title {
    font-size: 32px;
    font-weight: 500;
    color: #010343;
    line-height: 44px;
    margin-bottom: 21px;
  }
}

.service_content {
  width: 757px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  margin-bottom: 48px;
  .service_item {
    margin-bottom: 44px;
    dt {
      font-size: 19px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #010343;
      line-height: 36px;
    }
    dd {
      font-size: 19px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 300;
      color: #010343;
      line-height: 36px;
    }
  }

  .square {
    position: absolute;
    width: 93px;
    height: 93px;
    line-height: 93px;
    background: #1651dd;
    transform: rotate(45deg);
    font-size: 48px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    span {
      display: inline-block;
      transform: rotate(-45deg);
    }
  }

  .square_a {
    top: 60px;
    left: 188px;
  }

  .square_b {
    top: 60px;
    left: 368px;
  }

  .square_c {
    top: 213px;
    left: 188px;
  }

  .square_d {
    top: 213px;
    left: 368px;
  }
}
.value_add {
  .value_add_title {
    font-size: 32px;
    font-weight: 600;
    color: #010343;
    line-height: 44px;
    margin-bottom: 32px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  li {
    width: 331px;
    height: 67px;
    background: #f7f9fd;
    font-size: 19px;
    font-weight: 400;
    color: #010343;
    line-height: 67px;
    text-align: center;
    margin-bottom: 36px;
  }
}
</style>
